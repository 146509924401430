import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4341cea0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card",
  dir: "ltr"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "mb-2 mt-4" }
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_advanced_chat = _resolveComponent("vue-advanced-chat")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_vue_advanced_chat, {
          "current-user-id": _ctx.currentUserId,
          "room-id": _ctx.selectedRoomId,
          rooms: JSON.stringify(_ctx.rooms),
          messages: JSON.stringify(_ctx.messages),
          "room-actions": JSON.stringify(_ctx.roomActions),
          "show-reaction-emojis": "false",
          "show-audio": "false",
          "load-first-room": "true",
          "show-new-messages-divider": "false",
          "messages-loaded": _ctx.messagesLoaded,
          "rooms-loaded": _ctx.roomsLoaded,
          "message-actions": JSON.stringify(_ctx.messageActions),
          "text-messages": JSON.stringify(_ctx.textMessages),
          onAddRoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewTeacher($event.detail[0]))),
          onFetchMessages: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchMessages($event.detail[0]))),
          onSendMessage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendMessage($event.detail[0]))),
          onOpenFile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openFile($event.detail[0]))),
          onDeleteMessage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMessageHandler($event.detail[0])))
        }, null, 8, ["current-user-id", "room-id", "rooms", "messages", "room-actions", "messages-loaded", "rooms-loaded", "message-actions", "text-messages"])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.visibleAddTeacher,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visibleAddTeacher) = $event)),
      title: _ctx.t('chat.startNewConversationWithTeacher'),
      width: "50%",
      style: {"margin-top":"250px"},
      center: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, { onClick: _ctx.cancelAddTeacher }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            disabled: !_ctx.selectedTeacher,
            onClick: _ctx.handleAddTeacher
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("chat.addTeacher")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("selectStudent")), 1),
          _createVNode(_component_el_select, {
            placeholder: _ctx.t('selectStudent'),
            style: {"width":"100%"},
            modelValue: _ctx.selectedStudent,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedStudent) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (child) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: child.firstName + ' ' + child.lastName,
                  key: child._id,
                  value: child._id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(child.firstName + " " + child.lastName), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["placeholder", "modelValue"]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("selectTeacher")), 1),
          _createVNode(_component_el_select, {
            modelValue: _ctx.selectedTeacher,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedTeacher) = $event)),
            placeholder: _ctx.t('selectTeacher'),
            style: {"width":"100%"},
            filterable: "",
            disabled: !_ctx.selectedStudent
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teachers[_ctx.selectedStudent], (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item._id,
                  label: item.firstName + ' ' + item.lastName,
                  value: item._id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}